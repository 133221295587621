.SuperAdmin__ChangePassword {
  @media only screen and (max-width: 1370px) {
    margin-top: 130px !important;
  }
}

.MEXICO__ChangePassword {
  margin-left: 240px;
  margin-top: 60px;

  @media only screen and (max-width: 1370px) {
    margin-top: 130px !important;
  }

  @media only screen and (max-width: 820px) {
    // margin-top: 130px !important;
    margin-left: 15px;
    margin-top: 90px !important;
    margin-right: 15px;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 15px;
    margin-top: 0px !important;
    margin-right: 15px;
  }
}

.changePassword__body--voters {
  position: absolute;
  margin-top: 100px;
}

.changePassword__body__form__title {
  font-weight: 600;
  font-size: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.changePassword__body {
  margin-left: 25px;
  margin-top: 26px;
  margin-right: 40px;

  @media only screen and (max-width: 768px) {
    margin-top: 120px;
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
  }
}

.changePassword__body__formOuter {
  border-radius: 8px;
  min-height: 477px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  @media only screen and (max-width: 768px) {
    min-height: auto;
  }
}

.changePassword__body__formInner {
  padding: 48px 65px 48px 51px;
  @media only screen and (max-width: 768px) {
    padding: 48px 21px 48px 21px;
  }
}

.changePassword__body__form__inputContainer {
  width: 378px;

  @media only screen and (max-width: 768px) {
    width: auto;
  }
}

.changePassword__body__form__footer {
  padding: 28px 74px 29px 74px;
  border-top: 1px solid #f0eef5;

  @media only screen and (max-width: 768px) {
    padding: 31px 20px 25px 20px;
  }
}

.changePassword__body__form__footerBtn {
  width: 86px;
  height: 51px;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  background-color: #3c1b98;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.changePassword__banner--success {
  background-color: #50cd89;
  height: 62px;
  border-radius: 8px;

  margin-right: 40px;
  margin-left: 24px;

  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-top: 24px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 130px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: -100px;
  }
}

.changePassword__banner__txt {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}
