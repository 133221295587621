.addressInfo__question {
  display: flex;
  flex-flow: column;
}

.addressInfo__question__label {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}
