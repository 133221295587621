.ModalWrapper {
  //   max-width: 600px;
  //   width: 100%;
  width: 600px;

  @media only screen and (max-height: 478px) {
    max-height: 400px;
    height: calc(100% - 100px);
    overflow-y: scroll;
  }

  @media only screen and (max-width: 768px) {
    width: auto;
  }
}

.modalWrapper__INE__exampleBallot {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;

  @media only screen and (max-width: 768px) {
    // padding: 40px;
  }
}

.modalWrapper__INE__exampleBallot__img {
  // width: 387.9px;
  // height: 556px;
  max-width: 100%;
  resize: contain;
}

.confirmQrReqAdmin__icon {
  margin-bottom: 18.5px;
  width: 65px;
}

.confirmQrReqAdmin__txt {
  font-weight: 600;
  font-size: 20px;
  color: #3f4254;
  text-align: center;
}

.confirmQrReqAdmin {
  padding: 89px 73.5px 84px 73.5px;

  display: flex;
  flex-flow: column;
  align-items: center;
}

.ReportDownload__loading {
  // padding: 92px 123px 54px 123px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  max-height: 400px;
}

.ReportDownloaded {
  padding: 92px 123px 54px 123px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.reportDownloaded__icon {
  width: 76px;
}

.reportDownloaded__icon__title {
  font-weight: 500;
  font-size: 32px;
  color: #50cd89;
  margin-top: 15px;
}

.reportDownloaded__icon__subtitle {
  font-size: 24px;
  color: #3f4254;
  font-weight: 500;
  margin-top: 22px;
  text-align: center;
}

.ReportDownload__dateSelect {
  padding: 32px 30px 158px 30px;
}

.ReportDownload__dateSelect__inputWrap {
  display: flex;
  flex-flow: column;
}

.ReportDownload__dateSelect__input__label {
  font-size: 14px;
  font-weight: 500;
  color: #181c32;
  margin-bottom: 8px;
}

.extendSession__icons {
  display: flex;
  flex-flow: row;
}

.extendSession__body {
  padding: 50px 128px 29px 128px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.extendSession__body__sessionTime {
  margin-top: 50px;
}

.extendSession__body__txt {
  font-weight: 500;
  color: #3f4254;
  font-size: 20px;
  text-align: center;
  margin-top: 21px;
}

.extendSession__icons--clockProgress {
  margin-right: 24px;
}

.extendSession__icons--rightArrow {
  margin-right: 20px;
}

.extendSession__icons--circleRight {
}

.changePasswordSuccess,
.QRCodeChangeSuccess {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 91px 60px 87px 60px;
}

.changePasswordSuccess__icon,
.QRCodeChangeSuccess__icon {
  width: 65px;
  margin-bottom: 18px;
}

.changePasswordSuccess__txt,
.QRCodeChangeSuccess__txt {
  font-size: 20px;
  font-weight: 600;
  color: #3f4254;
}
