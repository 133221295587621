@import '../common.scss';

.ParticipantLocationInfo {
  padding: 35px;
}

.participantLocationInfo__resAddress {
  @include flex-column;
}

.participantLocationInfo__mailAddress {
  @include flex-column;
  margin-top: 35px;
}

.participantLocationInfo__resAddress__label,
.participantLocationInfo__mailAddress__label {
  @include label;
}

.participantLocationInfo__resAddress__value,
.participantLocationInfo__mailAddress__value {
  @include value;
}

.participantLocationInfo__mailAddress__value--light {
  @include value;
  font-weight: 400;
}
