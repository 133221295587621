$INE__pink: #3c1b98;

@mixin common__flex__row {
  display: flex;
  flex-flow: row;
}

@mixin common__flex__row__center {
  display: flex;
  flex-flow: row;
  align-items: center;
}

@mixin common__flex__column {
  display: flex;
  flex-flow: column;
}

@mixin common__flex__column__center {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@mixin common__flex__fullCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin common__label {
  font-weight: 400;
  font-size: 16px;
  color: #6c7289;
}

@mixin common__value {
  font-weight: 500;
  font-size: 18px;
  color: #181c32;
}

.common__backBtn {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  margin-right: 27px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
