@import '../common.scss';

.ParticipantPhotoInfo {
  padding: 35px;
}

.participantPhotoInfo__photo {
  @include flex-column;
}

.participantPhotoInfo__photo__label {
  font-size: 16px;
  font-weight: 500;
}

.participantPhotoInfo__photo__img {
  width: 300px;
  height: auto;
  margin-top: 18px;
  margin-right: 30px;
  object-fit: contain;
}

.participantPhotoInfo__photos {
  display: flex wrap;
}

.participantPhotoInfo__btns {
  display: flex;
  margin-top: 18px;
}

.participantPhotoInfo__btns__container--uploadNew {
  margin-right: 25px;
}
