@import '../../../../../../theme/variables.ems';

.userLogin__form {
  height: 597px;
}

.userLogin__form__header__welcomeTxt {
  font-size: 32px;
  font-weight: 600;
  color: $emsPrimaryColor;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.userLogin__form__body {
  padding: 62px 229.5px 73px 229.5px;

  @media only screen and (max-width: 768px) {
    padding: 47px 21.5px 50px 21.5px;
  }
}

.userLogin__form__body__inputBottomTxt {
  font-size: 14px;
  font-weight: 500;
  color: #181c32;
  display: flex;
  justify-content: flex-end;
}

.userLogin__form__body__inputBottomLink {
  margin-left: 5px;
}

.userLogin__form__footer {
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.userLogin__form__footer__btn {
  width: 370px;
  height: 51px;
  background-color: $emsPrimaryColor;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voter__userLogin {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.recaptcha__disclaimer {
  display: block;
  text-align: center;
  margin-top: 550px;
  margin-bottom: 25px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.recaptcha__disclaimerMobile {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    text-align: center;
    margin-top: 800px;
    margin-bottom: 25px;
  }
}

.recaptcha__disclaimerMobile--mt800 {
  @media only screen and (max-width: 768px) {
    margin-top: 800px;
  }
}

.recaptcha__disclaimerMobile--mt600 {
  @media only screen and (max-width: 768px) {
    margin-top: 600px;
  }
}

.recaptcha__disclaimerMobile--mt400 {
  @media only screen and (max-width: 768px) {
    margin-top: 400px;
  }
}
