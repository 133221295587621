@import '../../../../../../../../../theme/variables.ems';

.EventDisplayCard {
  width: 575px;
  //   min-height: 389px;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  margin-right: 30px;
  margin-bottom: 25px;

  @media only screen and (max-width: 768px) {
    margin-right: 17px;
  }
}

.eventDisplayCard__body {
  padding: 30px 36px 20.6px 25px;
  display: flex;
  flex-flow: row;
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.eventDisplayCard__body__content {
  margin-left: 35px;
  display: flex;
  flex-flow: column;

  @media only screen and (max-width: 768px) {
    margin-top: 11px;
    margin-left: 0px;
  }
}

.eventDisplayCard__body__content__label {
  font-size: 18px;
  font-weight: 400;
}

.eventDisplayCard__body__content__title {
  margin-top: 11px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 24px;
}

.eventDisplayCard__body__content__dividerWrap {
  margin-top: 25px;
  margin-bottom: 28px;
}

.eventDisplayCard__body__content__divider {
  color: #a1a5b7;
  font-weight: 400;
  font-size: 18px;

  margin-right: 28px;
}

.eventDisplayCard__body__content__vtClosed__label {
  font-weight: 400;
  font-size: 14px;
  color: #6c7289;
}

.eventDisplayCard__body__content__vtClosed__value {
  margin-left: 13px;
  font-size: 16px;
  color: $emsPrimaryColor;
  font-weight: 700;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
}

.eventDisplayCard__footer {
  border-top: 1px solid #e1e1ee;
  //   height: 63px;
  padding: 22px 25px 18px 25px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 38px 13px 0px;
  }
}

.eventDisplayCard__footer__link {
  color: $emsPrimaryColor;
  font-size: 16px;
  font-weight: 500;
}
