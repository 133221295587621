@import '../../../../../../resuable/styles/CommonStyles.scss';
@import '../../../../../../../theme/variables.ems';

.eventInfo__downloadBtnWrap {
  display: flex;
  flex-flow: row wrap !important;
}
.EventInfo {
  margin-top: 35px;
  border-radius: 8px;
  height: auto;

  background-color: #fff;

  padding: 27px 35px 33px 35px;
}

.eventInfo__desc__label,
.eventInfo__time__label {
  @include common__label;
  margin-bottom: 5px;
}

.eventInfo__desc__value,
.eventInfo__time__value {
  @include common__value;
}

.eventInfo__desc {
  @include common__flex__column;
  margin-left: 104px;
  margin-bottom: 25px;
}

.eventInfo__time {
  @include common__flex__row;
}

.eventInfo__time__icon {
  width: 55px;
  height: 55px;
  margin-right: 51px;
}

.eventInfo__time__txtWrap {
  @include common__flex__column;
}

.eventInfo__time__editWrap {
  @include common__flex__row__center;
  margin-left: 49px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.eventInfo__time__edit__label {
  @include common__label;
  margin-right: 5px;
}

.eventInfo__time__opening {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  width: 500px;
}

.eventInfo__time__closing {
  @include common__flex__row__center;
}

.eventInfo__district {
  margin-top: 25px;
}

.eventInfo__district__editWrap {
  // width: 100%;
}

.btn__downLoadList {
  padding: 6px 10px;

  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  color: #fff;
  background-color: $emsPrimaryColor;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
