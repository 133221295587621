@import '../../../../../../theme/variables.ems';

.authMethod__form {
  position: absolute;
  margin-top: 89px;
  max-width: 830px;
  width: 100%;

  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  @media only screen and (max-width: 1200px) {
    margin-top: 35px;
    margin-bottom: 100px !important;
    max-width: 100%;
    width: 100%;
  }
}

.authMethodLogin__form {
  position: absolute;
  margin-top: 89px;
  max-width: 830px;
  width: 100%;

  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  @media only screen and (max-width: 1200px) {
    margin-top: 35px;
  }
}

.authMethodLogin__form--spanish {
  max-width: 1100px !important;
}

.authMethod__form__body {
  padding: 40px 30px 58px 30px;

  @media only screen and (max-width: 768px) {
    padding: 25px 24px 25px 24px;
  }
}

// PHONE INFO SECTION
.authMethod__form__body__phoneWrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  padding: 19px 26px;
  border: 1px solid #b5b5c3;
  border-radius: 12px;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
    align-items: flex-start;
  }
}

.authMethod__form__body__phoneWrap__txtWrap {
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: column;
  }
}

.authMethod__form__body__phoneWrap__txtWrap__label,
.authMethod__form__body__phoneWrap__txtWrap__value,
.authMethod__form__body__usernameWrap__txtWrap__label {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-right: 18px;
}

.authMethod__form__body__phoneWrap__txtWrap__value {
  @media only screen and (max-width: 768px) {
    font-weight: 600;
  }
}

.authMethod__form__body__phoneWrap__disclaimer {
  font-size: 16px;
  font-weight: 500;
  color: #3f4254;

  @media only screen and (max-width: 768px) {
    margin-top: 10px;
  }
}

// USERNAME INFO SECTION
.authMethod__form__body__usernameWrap {
  // padding-top: 56px;
  padding-left: 35px;
  padding-right: 29px;
  display: flex;
  flex-flow: column;
  padding-bottom: 50px;

  @media only screen and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.authMethod__form__body__usernameWrap__txtWrap {
  padding: 18px;
  border: 1px solid #b5b5c3;
  border-radius: 12px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: column;
    margin-bottom: 32px;
  }
}

.authMethod__form__body__usernameWrap__txtWrap__value {
  font-size: 22px;
  font-weight: 600;
  color: $emsPrimaryColor;
}

.authMethod__form__body__usernameWrap__banner {
  height: 80px;
  width: 100%;
  border-radius: 12px;
  background-color: $emsPrimaryLightColor;

  padding: 15px 86px 10px 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;

  @media only screen and (max-width: 768px) {
    height: auto;
    margin-top: 0px;
    align-items: flex-start;

    padding: 13px 23px 25px 10px;
  }
}

.authMethod__form__infoIcon {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.authMethod__form__body__usernameWrap__banner__txt {
  margin-left: 16px;
  font-size: 16px;
  color: $emsPrimaryColor;
  font-weight: 500;
}

// AUTH METHOD SECTION

.authMethod__form__body__methodWrap {
  padding-top: 0px;
  padding-left: 31px;
  padding-bottom: 25px;

  @media only screen and (max-width: 768px) {
    padding: 32px 22px 40px 22px;
  }
}

.authMethod__form__body__methodWrap__txtWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 18px;
}

.authMethod__form__body__methodWrap__txtWrap__label {
  margin-right: 8px;
  font-size: 24px;
  color: #181c32;
  font-weight: 600;
}

.authMethod__form__body__methodWrap__selectTxt {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-left: 10px;
}

.authMethod__form__body__methodWrap__select--sms {
  margin-bottom: 15px;
}

// DISCLAIMER SECTION

.authMethod__form__body__disclaimBannerOuter {
  margin-left: 31px;
  margin-right: 29px;
  margin-bottom: 58px;
}
.authMethod__form__body__disclaimBanner {
  height: 80px;
  width: 100%;
  border-radius: 12px;
  background-color: #f7f7f7;

  padding: 15px 75px 10px 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
}
.authMethod__form__body__disclaimBanner__txt {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-left: 20px;
}

.authMethod__phoneInputWrap {
  max-width: 370px;
  margin-left: 30px;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin-right: 30px;
  }
}

.authMethod__phoneConatiner > input {
  height: 45px;
  // padding: 20px;
  background: #fff;
  padding-left: 15px;
  border-radius: 6px;
  border: 1px solid #000;
  width: 100%;

  color: #3f4254;

  &:focus {
    outline: none;
    border: 2px solid #000;
    box-shadow: 0px 0px 7px 3px rgba(0, 129, 143, 0.25);
  }
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a5b7;
  }
}

.authMethod__phoneConatiner--French {
  width: 410px !important;

  @media only screen and (max-width: 768px) {
    width: 300px !important;
  }
}

.authMethod__phoneErr {
  margin-left: 30px;
  margin-top: 5px;
  color: red;
}

.authMethod__phoneInputDisclaimer {
  margin-left: 30px;
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    margin-right: 30px;
  }
}
.authMethod__phoneInputDisclaimer__txt {
  color: $emsPrimaryColor;
  font-weight: 500;
  font-size: 14px;
}
