.UserDetails {
  background-color: #fff;
  border-radius: 8px;
}

.userDetails__email,
.userDetails__phone,
.userDetails__role {
  padding: 30px 33px;
  border-bottom: 1px solid #e1e1ee;

  display: flex;
  flex-flow: column;
}

.userDetails__suspend {
  padding: 30px 33px;
  display: flex;
  flex-flow: column;
}

.userDetails__label {
  font-size: 16px;
  font-weight: 400;
  color: #3f4254;
}

.userDetails__value {
  font-size: 18px;
  font-weight: 600;
  color: #181c32;
}

.userDetails__suspend__btn,
.userDetails__suspend__btn--restoreAccess {
  width: 121px;
  height: 51px;
  border-radius: 6px;
  background-color: #a1a5b7;
  font-size: 18px;
  font-weight: 600;
  border: none;
  color: #fff;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.userDetails__suspend__btn--restoreAccess {
  background-color: #3c1b98;
  width: 179px;
}

.userDetails__header {
  display: flex;
  flex-flow: row;
  margin-left: 44px;
  margin-bottom: 22px;
}

.userDetails__header__name {
  font-size: 18px;
  font-weight: 600;
  color: #181c32;
  margin-right: 14px;
}

.userDetails__header__status--active,
.userDetails__header__status--suspended {
  width: 57px;
  height: 26px;
  border-radius: 6px;
  background-color: #50cd89;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userDetails__header__status--suspended {
  background-color: #e30000;
  width: 85px;
}

.userDetails__header__status {
  background-color: #e30000;
}

.userDetails__header__status__txt {
  font-size: 12px;
  color: #fff;
}
