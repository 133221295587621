.verifyMethod__formWrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;

  height: 100% !important;

  @media only screen and (max-width: 768px) {
    // margin-bottom: 100px;
  }
}

.verifyMethod__form {
  position: absolute;
  margin-top: 89px;

  max-width: 830px;
  width: 100%;
  //   height: 465px;

  border-radius: 12px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  padding: 46px 190px 90px 190px;
}

.verifyMethod__formHeader {
  display: flex;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  margin-bottom: 30px;
}

.verifyMethod__formHeaderTitle {
  font-weight: 600;
  font-size: 26px;
}

.verifyMethod__formHeaderSubTitle {
  font-weight: 500;
  font-size: 18px;
  margin-top: 21px;
}

.voterVerify__checkboxOuterWrap {
  display: flex;

  justify-content: center;
}

.voterVerify__checkboxContainer {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: flex-start;
}

.voterVerify__sendBtn {
  width: 398px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #3c1b98;
  border-radius: 6px;
  border: none;
  margin-top: 22px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.voterVerify__checkboxWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.voterVerify__checkInput {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 10px;
}

.voterVerify__checkboxTxt {
  color: #3f4254;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}
