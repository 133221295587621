@import '../common.scss';

.ParticipantContactInfo {
  padding: 35px;
  display: flex;
}

.participantContactInfo__dob,
.participantContactInfo__phone {
  @include flex-column;
  margin-right: 60px;
}

.participantContactInfo__email {
  @include flex-column;
}

.participantContactInfo__dob__label,
.participantContactInfo__phone__label,
.participantContactInfo__email__label {
  @include label;
}

.participantContactInfo__dob__value,
.participantContactInfo__phone__value,
.participantContactInfo__email__value {
  @include value;
}
