// SECURTIY DISCLAIMER MODAL
.voterRegModals__securityDisclaimer {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;

  padding: 32px 55px 22px 55px;
}

.voterRegModals__securityDisc__icon {
  width: 90px;
  height: 90px;
  margin-bottom: 33px;
}

.voterRegModals__securityDisc__txt {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

// THANK YOU MODAL
.voterRegModals__thankYou {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;

  padding: 32px 43px 54px 43px;
}

.voterRegModals__thankYou__label {
  font-size: 29px;
  font-weight: 500;
  margin-bottom: 23px;
}

// PHOTO ID MODAL

.voterRegModals__photoID {
  padding: 39px 41px;
}

.voterRegModals__photoID__imgWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.voterRegModals__photoID__drivers {
  width: 210px;
  height: 132px;
  margin-bottom: 11px;
}

.voterRegModals__photoID__passport {
  width: 244px;
  height: 169px;
  margin-bottom: 11px;
}

.voterRegModals__photoID__imgLabel {
  font-size: 18px;
  font-weight: 500;
}

.voterRegModals__photoID__driversWrap {
  margin-right: 64px;
}

.voterRegModals__photoID__pointsWrap {
  display: flex;
  flex-flow: column;
}

.voterRegModals__photoID__pointTxt {
  font-size: 18px;
  margin-bottom: 10px;
}

// ADDRESS DISCLAIMER MODAL

.voterRegModals__addressDisclaimer {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 51px 30px 40px 30px;
}

.voterRegModals__addressDisclaimer__titleWrap {
  display: flex;
  flex-flow: column;
  text-align: center;
  margin-bottom: 25px;
}

.voterRegModals__addressDisclaimer__titleTxt {
  font-size: 26px;
  font-weight: 500;
}

.voterRegModals__addressDisclaimer__subTitleTxt {
  font-size: 22px;
  font-weight: 400;
}

.voterRegModals__addressDisclaimer__numbersWrap {
  display: flex;
  flex-flow: column;
  text-align: center;
}

.voterRegModals__addressDisclaimer__number1Txt {
  font-size: 24px;
  font-weight: 600;
  color: #3c1b98;
}

.voterRegModals__addressDisclaimer__number2Txt {
  font-size: 24px;
  font-weight: 600;
  color: #3c1b98;
}

.voterRegModals__addressDisclaimer__contactUsTxt {
  font-size: 22px;
  font-weight: 400;
  margin-top: 28px;
  margin-bottom: 28px;
}

.voterRegModals__addressDisclaimer__helpBtn {
  width: 168px;
  height: 57px;
  background-color: #3c1b98;
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
  font-size: 22px;
  border: none;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
