@import '../../../../../../theme/variables.ems';

.VoterAddressList {
  background-color: #e1edf9;
  height: 100vh;
  width: 100%;
}

.voterAddrList__container {
  padding-left: 265px;
  padding-top: 90px;
  padding-right: 95px;
}

.voterAddrList__tabWrap {
  display: flex;
  flex-flow: row;
  margin-left: 29px;
}

.voterAddrList__activeTab {
  color: $emsPrimaryColor;
  font-weight: 600;
  font-size: 16px;
  margin-right: 37px;
  border-bottom: 2px solid $emsPrimaryColor;
  padding-bottom: 5px;
}

.voterAddrList__inactiveTab {
  color: #3f4254;
  font-weight: 500;
  font-size: 16px;
  margin-right: 37px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.voterAddrList__table__actionBar {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.voterAddrList__table__actionBar__filterIcon {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.voterAddrList__table__actionBar__downloadIcon {
  margin-left: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voterAddrList__tableContanier {
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  margin-top: 25px;
  padding: 21px 27px;
  //   margin-right: 95px;
}

.voterAddrList__tableBody {
  height: 500px;
  height: 55vh;
  overflow-y: scroll;
}

.voterAddrList__tableActionWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

// TABLE -

.voterAddrList__tableHeader__columnLabel {
  font-weight: 600;
  font-size: 14px;
  color: #3f4254;
}

.voterAddrList__tableBody__rowItem--1,
.voterAddrList__tableBody__rowItem--2,
.voterAddrList__tableBody__rowItem--3,
.voterAddrList__tableBody__rowItem--4 {
  font-size: 14px;
  font-weight: 500;
  color: #3f4254;
}

.voterAddrList__tableBody__rowItem--2 {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

// TABLE NEW
.voterAddrList__tableHeader {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  background-color: #f2f3f7;
  margin-top: 17px;
  padding-left: 28px;
  padding-right: 46px;
  border-radius: 6px;
}

.voterAddrList__tableBody__row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-left: 28px;
  padding-right: 46px;
  margin-top: 15px;
  min-height: 62px;
  // background-color: red;
  border-bottom: 1px dashed #e4e6ef;
}

// HEADER WIDTHS
.voterAddrList__tableHeader__columnLabel--1 {
  // width: 5%;
  width: fit-content;
}

.voterAddrList__tableHeader__columnLabel--2 {
  width: 28%;
}

.voterAddrList__tableHeader__columnLabel--3 {
  width: 22%;
}

.voterAddrList__tableHeader__columnLabel--4 {
  // width: 10%;
  width: fit-content;
}

.voterAddrList__tableHeader__columnLabel--5 {
  width: 10%;
}

// ROW WIDTHS
.voterAddrList__tableBody__rowItem--1 {
  width: 5%;
}

.voterAddrList__tableBody__rowItem--2 {
  width: 25%;
}

.voterAddrList__tableBody__rowItem--3 {
  width: 20%;
}

.voterAddrList__tableBody__rowItem--4 {
  width: 11%;
}

.voterAddrList__footer {
  margin-top: 25px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-right: 50px;
}

.voterAddrList__footer__txt {
  font-weight: 500;
  font-size: 14px;
}

.voterAddrList__footer__txt__value {
  color: $emsPrimaryColor;
}

.voterAddrList__footer__pagination {
  display: flex;
  flex-flow: row;
}

.voterAddrList__footer__pagination__numberBtn--active {
  width: 28.48px;
  height: 28.48px;
  background-color: #000;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.voterAddrList__footer__pagination__numberBtn__value--active {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

.voterAddrList__footer__pagination__chevron--left,
.voterAddrList__footer__pagination__chevron--right {
  width: 28.48px;
  height: 28.48px;
  background-color: #fff;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.voterAddrList__footer__pagination__chevron--left {
  margin-right: 15px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voterAddrList__footer__pagination__chevron--right {
  margin-left: 15px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voterAddrList__footer__pagination__numberBtn--notActive {
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.voterAddrList__footer__pagination__numberBtn__value--notActive {
  font-weight: 500;
  font-size: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
