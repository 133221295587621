.eventEditModal__cancelBtn {
  height: 47px;
  width: fit-content;
  padding: 12px 20px;
  border-radius: 6px;
  border: none;

  color: #6c7289;
  background-color: #f0eef5;
  font-weight: 600;
  font-size: 15px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.VotingOpenDate {
  padding: 100px 26px 100px 26px;
  position: relative;
}

.eventEditModal__input {
  padding: 10px 23px;
  width: 100%;
  border: 1px solid #000000;
  height: 45px;
  border-radius: 6px;

  font-size: 16px;
  color: #3f4254;
}

.eventEditModal__dateTimeTxt {
  font-size: 16px;
  color: #3f4254;
}
