@import '../../../../../../theme/variables.ems';

.FilterModal {
  display: flex;
  flex-flow: row;
  padding: 18px 34px;
}

.filterModal__cancelBtn {
  height: 47px;
  width: fit-content;
  padding: 12px 20px;
  border-radius: 6px;
  border: none;

  color: $emsPrimaryColor;
  border: 1px solid $emsPrimaryColor;
  background-color: #fff;
  font-weight: 600;
  font-size: 15px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
