// INE STYLING
.voterStyles__INE__btn {
  background-color: #3c1b98 !important;
}

.voterStyles__INE__txt {
  color: #3c1b98 !important;
}

.voterStyles__INE__divider {
  background-color: #f5f8fa !important;
}

// NEUVOTE STYLING
.voterStyles__neuvote__btn {
  background-color: #1f3864 !important;
}

.voterStyles__neuvote__txt {
  color: #1f3864 !important;
}
