@import '../../user_dashboard/common.scss';

.EmailSent {
  //   justify-content: center;
  //   padding: 87px;
  //   padding-top: 87px;
}

.emailSent__telegramIcon {
  width: 75px;
  height: 75px;
}

.emailSent__title {
  color: #181c32;
  font-weight: 600;
  font-size: 24px;
  margin-top: 53px;
}

.emailSent__subtitle {
  font-weight: 500;
  font-size: 18px;
  color: #3f4254;
}

.emailSent__body {
  @include common__flex__column__center;
  text-align: center;
  padding: 87px 160px 71px 160px;

  @media only screen and (max-width: 768px) {
    padding: 60px 22.5px 71px 40px;
  }
}

.emailSent__footerTxt {
  margin-top: 68px;
  font-size: 16px;
}

.emailSent__btn {
  padding-left: 30px;
  padding-right: 30px;
  width: fit-content;
}
