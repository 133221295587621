@mixin label {
  font-size: 16px;
  font-weight: 400;
  color: #6c7289;
}

@mixin value {
  font-size: 16px;
  font-weight: 600;
}

@mixin flex-column {
  display: flex;
  flex-flow: column;
}
