@import '../../../../../theme/variables.ems';

.ModalHeaderWithLabel {
  display: flex;
  padding: 21px 25px;
  border-bottom: 1px solid #f0eef5;
}

.modalHeaderWithLabel__label {
  font-size: 22px;
  font-weight: 500;
  color: $emsPrimaryColor;
}
