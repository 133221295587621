// UPLOAD IN PROGRESS
.importAddrListM__inProgress {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  padding: 125px 106px 183px 106px;
  text-align: center;
}

.importAddrListM__inProgress__title {
  font-weight: 500;
  font-size: 24px;
  color: #3f4254;
}

.importAddrListM__inProgress__subtitle {
  font-weight: 400;
  font-size: 20px;
  color: #3f4254;
}

// UPLOAD SUCCESS
.importAddrListM__uploadSuccess {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;

  text-align: center;

  padding: 51px 79px 43px 79px;
}

.importAddrListM__uploadSuccess__title {
  font-weight: 500;
  font-size: 24px;
  color: #6c7289;
  margin-bottom: 27px;
}

.importAddrListM__uploadSuccess__subtitle {
  font-weight: 400;
  font-size: 22px;
  color: #3f4254;
  margin-bottom: 59px;
}

.importAddrListM__uploadSuccess__btnWrap {
  display: flex;
  flex-flow: row;
}

.importAddrList__banner {
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.importAddrListM__uploadSuccess__btn {
  width: 63px;
  height: 47px;

  font-weight: 600;
  font-size: 15px;

  border: none;
  border-radius: 6px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.importAddrListM__uploadSuccess__noBtn {
  margin-right: 26px;
  color: #6c7289;
  background-color: #f0eef5;
}

.importAddrListM__uploadSuccess__yesBtn {
  color: #fff;
  background-color: #3c1b98;
}

// FILE TOO BIG - MODAL
.importAddrListM__fileTooBigErr {
  display: flex;
  flex-flow: column;
  align-items: center;

  padding: 101px 76.5px 98px 76.5px;
}

.importAddrListM__fileTooBigErr__warningIcon {
  width: 70px;
  height: 70px;
  margin-bottom: 30px;
}

.importAddrListM__fileTooBigErr__txt,
.importAddrListM__wrongFileTypeErr__txt {
  color: #3f4254;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
}

// WRONG FILE TYPE - MODAL
.importAddrListM__wrongFileTypeErr {
  display: flex;
  flex-flow: column;
  align-items: center;

  padding: 40px 76.5px 24px 76.5px;
}

.importAddrListM__wrongFileTypeErr__subTxt {
  color: #3f4254;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  margin-top: 25px;
}

.importAddrListM__wrongFileTypeErr__warningIcon {
  width: 70px;
  height: 70px;
  margin-bottom: 40px;
}

.voterAdminModals__thankYou____header__title__usersAdded {
  font-size: 20px;
  font-weight: 500;
  color: #50cd89;
  line-height: 1.3;
}
.voterAdminModals__thankYou__subtitle__usersAdded {
  font-size: 18px;
  font-weight: 500;
  color: #50cd89;
  text-align: center;
}
.voterAdminModals__thankYou__subtitle__usersMessages {
  font-size: 16px;
  font-weight: 500;
  color: #3f4254;
  text-align: center;
}
