@import '../../../../../../theme/variables.ems';

.adminUsers__newUser__btn {
  width: fit-content;
  height: 46px;
  border: 2px solid $emsPrimaryColor;
  border-radius: 6px;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.adminUsers__newUser__btn__txt {
  font-size: 15px;
  color: $emsPrimaryColor;
  font-weight: 600;
  margin-left: 10px;
}

.adminUsers__newUser__btnOuter {
  position: absolute;
  right: 55px;
}

.adminUsers__header {
  margin-bottom: 16px;
}

.voterAddrList__tableContanier--adminUsers {
  min-height: 344px;
  height: 100%;
}
