.authAppSetup__form {
  height: 100% !important;
  max-height: 900px;
}

.authMethod__form--spanish {
  max-width: 1100px !important;
}

.authAppMethod__form--spanish {
  width: 863px !important;
}

.authAppSetup__form__body__methodWrap {
  padding-top: 0px;
  padding-left: 31px;
  padding-bottom: 35px;
  display: flex;
  flex-flow: column;
}

.authAppSetup__form__body__method__subtitle {
  color: #3f4254;
  font-size: 16px;
  font-weight: 400;
}

.authAppSetup__form__body__method__subtitleWrap {
  margin-bottom: 24px;
  max-width: 455px;

  @media only screen and (max-width: 768px) {
    margin-right: 20px;
  }
}

.authAppSetup__form__body__method__qrCode {
  width: 161px;
  height: 161px;
}

.authAppSetup__form__body__method__scanMeLabel {
  font-size: 23px;
  color: #3f4254;
  font-weight: 600;
  margin-left: 26px;
  margin-top: 10px;
}

.phoneVerify__form__actionBar__backBtn {
  width: 82px;
  height: 51px;
  border-radius: 6px;
  background-color: #a1a5b7;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border: none;
  margin-right: 30px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
