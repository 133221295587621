$civik-primary-blue: #3c1b98;

.verifyAccount__logoutBtnWrap {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 100px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.verifyAccount__logoutBtn {
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.verifyAccount__logoutBtnIcon {
  width: 21px;
  margin-right: 10px;
}
