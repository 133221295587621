@import '../../../../../theme/variables.ems';

.AdminSidebar,
.AdminSidebar--spanish {
  width: 240px;
  background-color: #fff;
  height: 100vh;
  z-index: 999;

  position: fixed;
  top: 0;
  z-index: 2;

  @media only screen and (max-width: 820px) {
    display: none;
  }
}

.AdminSidebar--spanish {
  @media only screen and (max-width: 965px) {
    display: none;
  }
}

.adminSidebar__logo {
  padding: 18px 21px;
}

.AdminSidebar__divider {
  border-top: 2px solid #e1e1ee;
  opacity: 1;
  margin-left: 19px;
  margin-right: 19px;
  margin-top: 0px;
  margin-bottom: 19px;
}

.adminSideBar__tabActiveWrap,
.adminSideBar__tabActiveWrap--Mexico {
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 40px;
  padding-left: 25px;
  background-color: #f1ecfb;
  margin-bottom: 13px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.adminSideBar__tabInactiveWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 40px;
  padding-left: 25px;
  margin-bottom: 13px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.adminSideBar__tabActiveTxt,
.adminSideBar__tabActiveTxt--Mexico {
  margin-left: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #7c3242;
}

.adminSideBar__tabInactiveTxt {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #3f4254;
}

// MEXICO --
.adminSideBar__tabActiveWrap--Mexico {
  background-color: #f1ecfb;
}

.adminSideBar__tabActiveTxt--Mexico {
  color: $emsPrimaryColor;
}

// ALL  --
.adminSideBar__tabActiveWrap {
  background-color: #f1ecfb;
}

.adminSideBar__tabActiveTxt {
  color: $emsPrimaryColor;
}
