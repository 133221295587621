.photoIdModal--view {
  padding: 22px 25px 41px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoIdModal--view-qr {
  max-width: 400px;
}

.photoIdModal--view__photoImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}
