@import '../../../../theme/variables.ems';

.civik-blue {
  color: #3c1b98;
}

.voterRegister__topBanner {
  width: 100%;
  height: 400px;
}

.voterRegister__formWrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 1000px;
}

.voterRegister__form {
  position: absolute;
  margin-top: 89px;

  max-width: 830px;
  width: 100%;

  border-radius: 12px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
}

.voterRegister__titleWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 21px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 25px;
    margin-top: 34px;
  }
}

.voterRegister__title {
  font-size: 30px;
  font-weight: 500;
  margin-right: 8px;

  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
}

.voterRegister__subtitle {
  font-size: 16px;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    margin-top: 25px;
  }
}

.voterRegister__sheildIcon {
  width: 32px;
  height: 32px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.voterRegister__bascInfoSection {
  margin-top: 35px;
}

.voterRegister__nameRow {
  display: flex;
  flex-flow: column;
}

.voterRegister__nameInputsWrap {
  display: flex;
  flex-flow: row;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.voterRegister__middleNameWrap {
  margin-left: 25px;
  margin-right: 25px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
}

.voterRegister__singleNameWrap {
  display: flex;
  flex-flow: row;
  margin-top: 12px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voterRegister__singleNameTxt {
  font-weight: 500;
  font-size: 14px;
  margin-left: 9px;
}

.voterRegister__birthRow {
  display: flex;
  flex-flow: row;
  margin-top: 24px;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.voterRegister__selectedDayWrap {
  margin-left: 24.85px;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
}

.voterRegister__selectedMonthWrap {
}

//
.voterRegister__contactRow {
  margin-top: 50px;
}
.voterRegister__divider {
  height: 55px;
  padding-left: 30px;
  background-color: #f0eef5;
  margin-left: -30px;
  margin-right: -30px;

  display: flex;
  align-items: center;
}

.voterRegister__dividerLabel {
  font-size: 20px;
  color: #000;
  font-weight: 500;
}

//

.voterRegister__emailInputsWrap {
  margin-top: 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.voterRegister__emailWrap {
  width: 100%;
  margin-right: 25px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.voterRegister__confirmEmailWrap {
  width: 100%;
}

.voterRegister__phoneWrap {
  margin-top: 25px;
  display: flex;
  flex-flow: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.voterRegister__phoneInputWrap {
  // margin-top: 25px;
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.voterRegister__phoneDummyWrap {
  width: 50%;
  margin-left: 25px;
  // background-color: red;
}

//

.voterRegister__addressRow {
  margin-top: 30px;
}

.voterRegister__sameAddressWrap {
  margin-top: 25px;
}

.voterRegister__addressWrap {
  margin-top: 25px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.voterRegister__addressInputWrap {
  width: 100%;
  margin-right: 25px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.voterRegister__locationWrap {
  margin-top: 25px;
  margin-bottom: 40px;
  display: flex;
  flex-flow: column;
}

.voterRegister__cityInputWrap {
  max-width: 174px;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.voterRegister__provinceDropdownWrap {
  margin-left: 25px;
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
}

.voterRegister__postalInputWrap {
  margin-left: 25px;
  max-width: 174px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    max-width: 100%;
  }
}
//

.voterRegister__addressDisclaimer {
  font-size: 20px;
  font-weight: 500;
}

.voterRegister__addressDisclaimerLink {
  color: #70b6c3;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
  }
}
//

.voterRegister__uploadIDWrap {
  display: flex;
  flex-flow: column;
}

.voterRegister__uploadBtnWrap {
  display: flex;
  align-items: center;
}

.voterRegister__uploadBtn {
  background-color: $emsPrimaryColor;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  width: 135px;
  border-radius: 6px;
  border: none;
  margin-top: 7px;
  margin-right: 22px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voterRegister__uploadIDLabel {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.voterRegister__uploadDisclaimerTxt {
  font-size: 14px;
  font-weight: 500;
  color: $emsPrimaryColor;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
  }
}

.voterRegister__uploadDividerTop {
  margin-left: -30px;
  margin-right: -30px;

  margin-bottom: 35px;
  font-weight: bold;

  height: 1.5px !important;
  border: none;
  color: #e1e1ee;
}

.voterRegister__uploadDividerBottom {
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 32px;
  height: 1.5px !important;
  border: none;
  color: #e1e1ee;

  margin-bottom: 30px;
}

.voterRegister__termsTxt {
  font-size: 14px;
  font-weight: 400;
  margin-left: 13px;
}

.voterRegister__termsDividerBottom {
  margin-left: -30px;
  margin-right: -30px;
  color: #f0eef5;
  height: 1.5px !important;
  border: none;

  margin-top: 30px;
}

//

.voterRegister__footerWrap {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.voterRegister__clearFormBtn {
  height: 47px;
  width: 125px;
  border-radius: 6px;
  background-color: #f0eef5;
  color: #6c7289;
  font-weight: 600;
  font-size: 15px;
  border: none;
  margin-right: 25px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voterRegister__cancelBtn {
  height: 47px;
  width: 95px;
  border-radius: 6px;

  background-color: #f0eef5;
  color: #6c7289;
  font-weight: 600;
  font-size: 15px;
  border: none;
  margin-right: 25px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voterRegister__nextBtn {
  height: 47px;
  width: 75px;
  border-radius: 6px;

  background-color: $emsPrimaryColor;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
//

.voterRegister__inputErrorsWrap {
  margin-top: 11px;
  margin-bottom: 9px;
}

.SelectField__voterErrWrap {
  margin-top: 11px;
}

.voterRegister__singleInputErr {
  margin-top: 10px;
}

.voterRegister__locationInputsWrap {
  display: flex;
  flex-flow: row;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.voterRegister__uploadedPhoto {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 300px;
  border-radius: 6px;
}
