.VoterModalHeader {
  padding: 23px 25px 21px 20px;
  border-bottom: 1px solid #f0eef5;
}

.voterModalHeader__label {
  font-size: 22px;
  font-weight: 500;
  color: #3f4254;
}
