@import '../../../../../../theme/variables.ems';

.ModalFooter {
  padding: 25px 20px 25px 30px;
  border-top: 1px solid #f0eef5;
  display: flex;
  justify-content: flex-end;
}

.modalFooter__primaryBtn,
.modalFooter__cancelBtn {
  height: 47px;
  width: fit-content;
  padding: 12px 20px;
  border-radius: 6px;
  border: none;

  color: #fff;
  // background-color: #3c1b98;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.modalFooter__primaryBtn {
  background-color: $emsPrimaryColor;
}

.modalFooter__cancelBtn {
  background-color: #f0eef5;
  color: #6c7289;
  margin-right: 25px;
}
