.PdfViewerPage {
  display: flex;
  flex-flow: column;
  padding: 70px;
  padding-left: 270px;
  margin-top: 100px;
  @media only screen and (max-width: 768px) {
    margin-top: 120px;
    display: flex;
    flex-flow: column;
    padding-left: 10px;
  }
}
