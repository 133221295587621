@import '../../../../../theme/variables.ems';

.uploadPhotoID__anotherDocBtn {
  display: flex;
  margin-top: 32px;
  margin-bottom: 38px;
  width: fit-content;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.uploadPhotoID__anotherDocBtn__label {
  color: $emsPrimaryColor;
  font-weight: 500;
  font-size: 16px;
  margin-left: 16px;
}
