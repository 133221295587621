.LabelAndValue {
  display: flex;
  flex-flow: column;
}

.labelAndValue__label {
  font-size: 16px;
  font-weight: 400;
  color: #6c7289;
}

.labelAndValue__value {
  font-size: 16px;
  font-weight: 600;
}
