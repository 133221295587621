.CheckBox {
  margin-top: 30px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.checkBox__input {
  display: flex;
  align-items: flex-start;
}

.checkBox__input__field {
  border: 1px solid #000 !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: 0.2px !important;
}

.checkBox__input__label {
  margin-left: 15px;
  font-size: 14px;
}
